import Router from "vue-router";
import Vue from "vue";

import loginer from "@/services/loginer";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      props: (router) => ({ next: router.query.next }),
      component: () => import("@/Pages/Login"),
      meta: { freeAccess: true },
    },
    {
      path: "/",
      name: "home",
      component: () => import("@/Pages/Index"),
      meta: { freeAccess: false },
      alias: "/home",
    },

    {
      path: "/solded-tickets",
      name: "solded-tickets",
      component: () => import("@/Pages/SoldedTickets"),
      props: (router) => ({
        text: router.query.text,
        minPrice: router.query.minPrice,
        maxPrice: router.query.maxPrice,
        orderStatus: router.query.orderStatus,
        provider: router.query.provider,
        page: router.query.page,
        take: router.query.take,
        type: router.query.type,
      }),
      meta: { freeAccess: false },
    },
    {
      path: "/employees",
      name: "employees",
      component: () => import("@/Pages/EmployeesList"),
      meta: { freeAccess: false },
    },
    {
      path: "/no-rights",
      name: "no-rights",
      component: () => import("@/Pages/NoRights"),
      meta: { freeAccess: false },
    },

    {
      path: "/find-tkt",
      name: "find-tkt",
      component: () => import("@/Pages/FindTkt"),
      meta: { freeAccess: false, keepAlive: true },
    },
    {
      path: "/found-tickets",
      name: "found-tickets",
      component: () => import("@/Pages/FoundTickets"),
      props: (router) => ({
        cls: router.query.cls,
        src: router.query.src,
        dest: router.query.dest,
        date: router.query.date,
        back: router.query.back,
        adults: router.query.adults,
        kids: router.query.kids,
        bbs: router.query.bbs,
        direct: router.query.direct,
        city1: router.query.city1,
        city2: router.query.city2,
      }),
      meta: { freeAccess: false, keepAlive: false },
    },
    {
      path: "/buy-ticket",
      name: "buy-ticket",
      component: () => import("@/Pages/BuyTicket"),
      props: (router) => ({
        adt: router.query.adt,
        cnn: router.query.cnn,
        inf: router.query.inf,
        provider: router.query.provider,
        providerConfigOwner: router.query.providerConfigOwner,
        id: router.query.id,
      }),
      meta: { freeAccess: false },
    },
    {
      path: "/show-receipt",
      name: "show-receipt",
      component: () => import("@/Pages/ShowReceipt"),
      props: (router) => ({
        regnum: router.query.regnum,
        surname: router.query.surname,
        pult: router.query.pult,
        isRefundable: router.query.isRefundable,
        byBuy: router.query.byBuy,
      }),
      meta: { freeAccess: false },
    },
    {
      path: "/support",
      name: "support",
      component: () => import("@/Pages/Support"),
      meta: { freeAccess: false },
    },
    {
      path: "/adminka",
      name: "adminka",
      component: () => import("@/Pages/Adminka"),
      meta: { freeAccess: false },
    },
    {
      path: "/qrs",
      name: "qrs",
      component: () => import("@/Pages/QrS"),
      props: (router) => ({
        id: router.query.id,
      }),
      meta: { freeAccess: false },
    },
    {
      path: "/companies",
      name: "companies",
      component: () => import("@/Pages/Companies"),
      meta: { freeAccess: false },
    },

    // Для ЖД
    {
      path: "/trs-list",
      name: "trs-list",
      component: () => import("@/Pages/TrainsList"),
      props: (router) => ({
        src: router.query.src,
        dest: router.query.dest,
        codeSrc: router.query.codeSrc,
        codeDest: router.query.codeDest,
        date: router.query.date,
        adults: router.query.adults,
        kids: router.query.kids,
        bbs: router.query.bbs,
        oneway: router.query.oneway === "true",
        dateFrom: router.query.dateFrom,
        segmentOne: router.query.segmentOne,
      }),
      meta: { freeAccess: false, keepAlive: false },
    },
    {
      path: "/book-train",
      name: "book-train",
      component: () => import("@/Pages/BookTrain"),
      props: (router) => ({
        origin: router.query.origin,
        dest: router.query.dest,
        train: router.query.train,
        adults: router.query.adults,
        kids: router.query.kids,
        bbs: router.query.bbs,
        searchId: router.query.searchId,
        departureDateTime: router.query.departureDateTime,
        trainNumber: router.query.trainNumber,
        oneway: router.query.oneway === "true",
        dateFrom: router.query.dateFrom,
        src: router.query.src,
        destt: router.query.dest,
        codeSrc: router.query.codeSrc,
        codeDest: router.query.codeDest,
        segmentOne: router.query.segmentOne,
      }),
      meta: { freeAccess: false, keepAlive: false },
    },
    {
      path: "/success-railway",
      name: "success-railway",
      component: () => import("@/Pages/SuccessRailway"),
      meta: { freeAccess: false },
    },
    {
      path: "/external-screen",
      name: "external-screen",
      component: () => import("@/components/layout/ScreenLayout"),
      meta: { freeAccess: false },
      children: [
        {
          path: "control",
          name: "control",
          component: () => import("@/Pages/ExternalScreenControlPanel"),
          meta: {
            freeAccess: false,
            noError: true,
          },
        },
        {
          path: "show",
          name: "show",
          component: () => import("@/Pages/ExternalScreen"),
          props: (router) => ({
            id: router.query.id,
          }),
          meta: {
            freeAccess: true,
            noHeader: true,
            noFooter: true,
            noError: true,
          },
        },
      ],
    },
  ],
});
router.beforeEach(async (to, from, next) => {
  if (to.meta.freeAccess) {
    next();
  } else {
    var condation = false;
    try {
      condation = await loginer.checkToken();
    } catch {
      condation = false;
    }
    if (condation) {
      next();
    } else {
      // попробовать
      //next({ name: 'login', query: { next: to.fullPath } });
      next("/login" + `?next=${encodeURIComponent(to.name)}`);
    }
  }
});

export default router;
